import React, { useContext, useState, useReducer, useRef, useEffect } from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { PatternFormat } from 'react-number-format'
import { useNavigate } from 'react-router-dom'
import formatter from 'phone-formatter'

import * as Yup from 'yup'
import dayjs from 'dayjs'

import { ExclamationCircleIcon } from '@heroicons/react/outline'

import RequestLoanContext from 'context/RequestLoan'
import AuthContext from 'context/Auth'
import Button from 'components/Button'
import { ROUTES } from 'routes'
import { CITIES_PR } from 'constants'
import DatePicker from 'components/DatePicker'

export default function RequestLoanOwner() {
    const navigate = useNavigate()
    const [error, setError] = useState()
    const { state: requestState, saveState } = useContext(RequestLoanContext)
    const { state: AuthState, createUser, createAuthToken } = useContext(AuthContext)
    const form = useRef()
    const [loading, setLoading] = useState(false)
    const [ssnFormat, setSSNFormat] = useReducer((state, updates) => ({ ...state, ...updates }), {
        ssn: '#########'
    })

    const onToggleSSN = (event, type) => {
        let ssn

        const label = event.currentTarget
        const input = label.parentNode.firstChild
        const value = form.current.values.ssn ?? ''

        if (label.innerHTML === 'Ver SSN') {
            setSSNFormat({
                ssn: '###-##-####'
            })
            ssn = formatter.format(value, 'NNN-NN-NNNN').replaceAll('N', '')
            input.type = 'text'
            label.innerHTML = 'Ocultar SSN'
        } else {
            setSSNFormat({
                ssn: '#########'
            })
            ssn = value.replaceAll('-', '')
            input.type = 'password'
            label.innerHTML = 'Ver SSN'
        }

        form.current.setFieldValue(type, ssn)
        form.current.setFieldTouched(type, false, false)
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().trim().required('Este campo es requerido'),
        surname: Yup.string().trim().required('Este campo es requerido'),
        email: Yup.string().required('Este campo es requerido').email('Correo electrónico inválido'),
        birthdate: Yup.string()
            .required('Este campo es requerido')
            .test('legalAge', 'Debes tener 21 años para poder registrarte', val => {
                if (val === undefined) return false

                let currentDate = dayjs()
                let diffYears = currentDate.diff(val, 'year')

                return diffYears >= 21
            }),
        ssn: Yup.string()
            .required('Este campo es requerido')
            .test('ssnLength', 'Escribe todo los dígitos de tu SNN', val => {
                if (val === undefined) {
                    return false
                }

                if (ssnFormat.ssn === '###-##-####' && val.length === 11) {
                    return true
                }
                if (ssnFormat.ssn === '#########' && val.length === 9) {
                    return true
                }
            }),
        address: Yup.string().required('Este campo es requerido'),
        city: Yup.string().required('Este campo es requerido'),
        state: Yup.string().required('Este campo es requerido'),
        zipcode: Yup.string()
            .required('Este campo es requerido')
            .test('minZip', 'El código debe ser de 5 dígitos', val => val && val.length === 5)
            .test('zipPR', 'El código postal debe empezar con 00', val => val && val.startsWith('00')),
        terms_and_conditions: Yup.boolean().required('Los términos y condiciones deben ser aceptados.').oneOf([true], 'Los términos y condiciones deben ser aceptados.')
    })

    const onSubmit = async values => {
        try {
            setLoading(true)
            setError()

            const { terms_and_conditions, ...cleanValues } = values
            delete requestState.business.completed
            delete AuthState.application.completed
            delete cleanValues.completed
            const user = await createUser({
                user: { ...cleanValues, phoneNumber: AuthState.phone },
                business: requestState.business,
                application: AuthState.application
            })

            if (!user.success) {
                setLoading(false)
                if (user.data.response.data.messages.meta.target[0].includes('ssn')) {
                    setError('Problemas al guardar tu SSN, por favor contacta a support@kiwicapital.co')
                } else {
                    setError('Problemas al guardar tu información, por favor contacta a support@kiwicapital.co')
                }
                return false
            }

            await createAuthToken({ token: AuthState.token })

            saveState({
                ...requestState,
                owner: user.data
            })

            navigate(ROUTES.REVIEWING)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const onBackward = () => {
        saveState({
            ...requestState,
            owner: {
                ...requestState.owner,
                completed: false
            },
            business: {
                ...requestState.business,
                completed: false
            }
        })

        navigate(ROUTES.BUSINESS)
    }

    useEffect(() => {
        if (!requestState.business.completed) navigate(ROUTES.BUSINESS)
    }, [])

    return (
        <Formik initialValues={requestState.owner} validationSchema={validationSchema} onSubmit={onSubmit} innerRef={form}>
            {({ errors, touched }) => (
                <div className="max-w-4xl py-10 m-auto">
                    <Form className="flex flex-col bg-white px-6 sm:px-12" autoComplete="off">
                        <div className="text-center mb-12">
                            <h1 className="text-gray-900 font-semibold sm:text-4xl text-3xl mb-2">Información del propietario</h1>
                        </div>

                        <div className="grid md:grid-cols-2 gap-4">
                            <div>
                                <label htmlFor="name" className="inline-block text-sm mb-2">
                                    Nombre
                                </label>
                                <Field
                                    id="name"
                                    name="name"
                                    placeholder="Ingresa el nombre del propietario"
                                    className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.name && touched.name ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-sm' : 'border-gray-200 focus:border-primary-500 placeholder:text-sm'}`}
                                    disabled={loading}
                                />
                                <ErrorMessage name="name" component="small" className="text-red-500 inline-block text-xs w-full" />
                            </div>
                            <div>
                                <label htmlFor="surname" className="inline-block text-sm mb-2">
                                    Apellido
                                </label>
                                <Field
                                    id="surname"
                                    name="surname"
                                    className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.surname && touched.surname ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-sm' : 'border-gray-200 focus:border-primary-500 placeholder:text-sm'}`}
                                    disabled={loading}
                                    placeholder="Ingresa los apellidos del propietario"
                                />
                                <ErrorMessage name="surname" component="small" className="text-red-500 inline-block text-s w-full" />
                            </div>
                            <div>
                                <label htmlFor="email" className="inline-block text-sm mb-2">
                                    Correo electrónico
                                </label>
                                <Field
                                    id="email"
                                    name="email"
                                    type="email"
                                    className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.email && touched.email ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-sm' : 'border-gray-200 focus:border-primary-500 placeholder:text-sm'}`}
                                    disabled={loading}
                                    placeholder="Ingresa el correo electrónico del propietario"
                                />
                                <ErrorMessage name="email" component="small" className="text-red-500 inline-block text-xs w-full" />
                            </div>
                            <div>
                                <label htmlFor="birthdate" className="inline-block text-sm mb-2">
                                    Fecha de nacimiento
                                </label>
                                <Field name="birthdate" component={DatePicker} />
                                {/* <Field
                                    id="birthdate"
                                    name="birthdate"
                                    type="date"
                                    max="3000-01-01"
                                    className={`focus:ring-0 appearance-none w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.birthdate && touched.birthdate ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                    disabled={loading}
                                /> */}
                                <ErrorMessage name="birthdate" component="small" className="text-red-500 inline-block text-s w-full" />
                            </div>
                            <div>
                                <label htmlFor="ssn" className="inline-block text-sm mb-2">
                                    Número de seguridad social
                                </label>
                                <div className="relative">
                                    <Field name="ssn" id="ssn">
                                        {({ field, ...props }) => (
                                            <PatternFormat
                                                {...field}
                                                {...props}
                                                format={ssnFormat.ssn}
                                                mask=""
                                                placeholder="000-00-0000"
                                                type="password"
                                                className={`focus:ring-0 border-red-kiwi outline-none w-full rounded-lg py-2 px-4 border border-solid  ${
                                                    errors.ssn && touched.ssn
                                                        ? 'border-red-500 focus:border-red-500 border-5 bg-red-500/5 placeholder:text-red-500/50 placeholder:text-sm'
                                                        : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi placeholder:text-sm'
                                                }`}
                                                autoComplete="off"
                                            />
                                        )}
                                    </Field>
                                    <small className={`cursor-pointer absolute right-4 top-1/2 -translate-y-1/2 ${errors.ssn && touched.ssn ? 'text-red-500' : 'text-blue-kiwi'}`} onClick={e => onToggleSSN(e, 'ssn')}>
                                        Ver SSN
                                    </small>
                                </div>
                                <ErrorMessage name="ssn" component="small" className="text-red-500 inline-block text-s w-full" />
                            </div>
                        </div>

                        <div className="flex flex-col gap-4 mt-8">
                            <h2 className="text-gray-800 text-lg font-semibold">¿Dónde vives?</h2>

                            <div className="grid md:grid-cols-2 gap-4">
                                <div>
                                    <label htmlFor="address" className="inline-block text-sm mb-2">
                                        Dirección de tu residencia
                                    </label>
                                    <Field
                                        id="address"
                                        name="address"
                                        className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${
                                            errors.address && touched.address ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-sm' : 'border-gray-200 focus:border-primary-500 placeholder:text-sm'
                                        }`}
                                        disabled={loading}
                                        placeholder="Ingresa la dirección de tu residencia"
                                    />
                                    <ErrorMessage name="address" component="small" className="text-red-500 inline-block text-xs w-full" />
                                </div>
                                <div>
                                    <label htmlFor="suite" className="inline-block text-sm mb-2">
                                        Suite/Apt/Unidad # <span className="text-gray-500">(Opcional)</span>
                                    </label>
                                    <Field
                                        id="suite"
                                        name="suite"
                                        className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.suite && touched.suite ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-sm' : 'border-gray-200 focus:border-primary-500 placeholder:text-sm'}`}
                                        disabled={loading}
                                        placeholder="Ingresa el tipo de dirección"
                                    />
                                    <ErrorMessage name="suite" component="small" className="text-red-500 inline-block text-xs w-full" />
                                </div>
                                <div>
                                    <label htmlFor="city" className="inline-block text-sm mb-2">
                                        Ciudad
                                    </label>
                                    <div className="relative flex items-center cursor-pointer after:w-[8px] after:h-[8px] after:border-black/70 after:border-b after:border-r after:transform after:rotate-45 after:absolute after:right-4 after:bottom-5">
                                        <Field
                                            as="select"
                                            id="city"
                                            name="city"
                                            placeholder="Seleccionar"
                                            className={`appearance-none focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.city && touched.city ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                            disabled={loading}>
                                            <option disabled value="">
                                                Seleccionar
                                            </option>
                                            {CITIES_PR.map((value, i) => (
                                                <option key={i} value={value.name}>
                                                    {value.name}
                                                </option>
                                            ))}
                                        </Field>
                                    </div>
                                    <ErrorMessage name="city" component="small" className="text-red-500 inline-block text-s w-full" />
                                </div>
                                <div>
                                    <label htmlFor="state" className="inline-block text-sm mb-2">
                                        Estado
                                    </label>
                                    <Field
                                        id="state"
                                        name="state"
                                        className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.state && touched.state ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                        disabled={true}
                                    />
                                    <ErrorMessage name="state" component="small" className="text-red-500 inline-block text-s w-full" />
                                </div>

                                <div>
                                    <label htmlFor="zipcode" className="inline-block text-sm mb-2">
                                        Código postal
                                    </label>
                                    <Field id="zipcode" name="zipcode">
                                        {({
                                            field, // { name, value, onChange, onBlur }
                                            form: { touched, errors }
                                        }) => (
                                            <PatternFormat
                                                className={`w-full rounded-lg py-2 border px-4 ${
                                                    errors.zipcode && touched.zipcode ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-red-500/50 placeholder:text-sm' : 'border-gray-200 bg-white focus:border-primary-500 placeholder:text-gray-400 placeholder:text-sm'
                                                }`}
                                                format="#####"
                                                placeholder="Ingresa el código postal (ej. 00000)"
                                                mask=""
                                                {...field}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="zipcode" component="small" className="text-red-500 inline-block text-xs w-full" />
                                </div>
                            </div>
                        </div>

                        <div className="mt-8 text-xs">
                            <div className="flex gap-2 py-2 px-4 bg-[#FFF7EB] rounded-lg items-center">
                                <div className="bg-[#FFE9C7] text-[#FFB84D] p-2 rounded-full">
                                    <ExclamationCircleIcon className="h-5 w-5" />
                                </div>
                                <p>
                                    <b>NO</b> hacemos ninguna indagación a tu reporte de crédito personal
                                </p>
                            </div>

                            <div className="mt-6">
                                <Field type="checkbox" id="terms_and_conditions" name="terms_and_conditions">
                                    {({ field }) => (
                                        <div className="flex items-start gap-3">
                                            <input type="checkbox" {...field} className="mr-1" />
                                            <label htmlFor="terms_and_conditions">
                                                Acepto los{' '}
                                                <a className="font-bold" href={`${process.env.REACT_APP_LANDING}/terms-use`} target="_blank" rel="noreferrer">
                                                    Términos y condiciones de uso
                                                </a>{' '}
                                                y{' '}
                                                <a className="font-bold" href={`${process.env.REACT_APP_LANDING}/privacy-policy`} target="_blank" rel="noreferrer">
                                                    Política de privacidad
                                                </a>
                                                . También autorizo el{' '}
                                                <a className="font-bold" href={`${process.env.REACT_APP_LANDING}/business-verification-consent`} target="_blank" rel="noreferrer">
                                                    Consentimiento de verificación de negocio
                                                </a>{' '}
                                                a Kiwi Financial Inc a obtener información sobre mi negocio, que facilite y complemente el proceso de evaluación de crédito.
                                                <br />
                                            </label>
                                        </div>
                                    )}
                                </Field>
                                <ErrorMessage name="terms_and_conditions" component="small" className="text-red-500 ml-7 inline-block text-sm w-full" />
                            </div>
                        </div>

                        <div className="flex flex-col gap-6 items-center justify-center mt-12">
                            {error && <p className="text-red-500 inline-block text-sm text-center w-full">{error}</p>}

                            <div className="flex items-center justify-center gap-3">
                                <Button onClick={onBackward} className="bg-white border border-gray-500 text-gray-500 rounded-lg px-5" type="button">
                                    Volver
                                </Button>
                                <Button className="bg-primary-500 text-white text-sm px-10 py-2 rounded-md w-auto" type="submit" loading={loading}>
                                    Enviar solicitud
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    )
}
