import React, { Fragment, useContext, useState, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import RequestLoanContext from 'context/RequestLoan'
import { PlaidService } from 'services/plaid/plaid.service'

import BankAccountBanner from 'components/BankAccountBanner'
import HeaderApp from 'components/Header'
import { ROUTES } from 'routes'

const EMPTY_ARRAY = 0

export default function ApprovedLoan() {
    const navigate = useNavigate()

    const { state, saveState, getCurrentLoan } = useContext(RequestLoanContext)

    const [isLoading, setLoading] = useState(true)
    const [hasPlaid, setHasPlaid] = useState(false)

    const getCurrentPlaid = async () => {
        const response = await PlaidService.getCurrentPlaid(state.user.id)

        if (!response.success || response.data.length === EMPTY_ARRAY) return setHasPlaid(false)

        setHasPlaid(true)
    }

    const setProperRoutes = async () => {
        try {
            const { data } = await getCurrentLoan()

            saveState({
                ...state,
                loan: data
            })

            if (!data) {
                return navigate(ROUTES.OFFERS)
            }

            if (data.loanpro_id) {
                return navigate(ROUTES.BALANCE)
            }

            if (data.status === 'reviewing' && data.payment_profile_id !== null && !data.loanpro_id) {
                return navigate(ROUTES.LOAN_SUCCESS)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (state.application.status !== 'approved') {
            return navigate(ROUTES.PROFILE_USER)
        }
        getCurrentPlaid()
        setProperRoutes()
    }, [])

    return (
        <main className="w-full min-h-full flex flex-col">
            <HeaderApp />
            {isLoading ? (
                <section className="flex-grow px-2 sm:px-6 lg:px-8 py-10 flex items-center justify-center">
                    <div className="mx-auto max-w-7xl w-full flex items-center justify-center">
                        <svg className="w-12 h-12 animate-spin text-primary-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 4.75V6.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M17.1266 6.87347L16.0659 7.93413" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M19.25 12L17.75 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M17.1266 17.1265L16.0659 16.0659" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M12 17.75V19.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M7.9342 16.0659L6.87354 17.1265" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M6.25 12L4.75 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M7.9342 7.93413L6.87354 6.87347" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </div>
                </section>
            ) : (
                <Fragment>
                    {!hasPlaid && <BankAccountBanner hasCreated={setHasPlaid} />}
                    <section className="flex-grow px-2 sm:px-6 lg:px-8 flex justify-center">
                        <div className="mx-auto max-w-7xl w-full">
                            <Outlet />
                        </div>
                    </section>
                </Fragment>
            )}
        </main>
    )
}
