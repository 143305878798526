import React, { useContext, useEffect } from 'react'
import { ROUTES } from 'routes'
import { useNavigate } from 'react-router-dom'
import RejectedImg from 'assets/images/rejected.svg'
import RequestLoanContext from 'context/RequestLoan'
import Header from 'components/Header'

export default function RequestLoanRejected() {
    const navigate = useNavigate()
    const { state } = useContext(RequestLoanContext)

    const pagesValidations = () => {
        if (!state.application) {
            return null
        }

        if (state.application.status === 'approved') {
            navigate(ROUTES.OFFERS)
        }

        if (state.application.status === 'declined') {
            navigate(ROUTES.DECLINED)
        }
    }

    useEffect(() => {
        pagesValidations()
    }, [state.application])

    return (
        <>
            <div>
                <Header />
            </div>
            <div className="max-w-4xl py-10 m-auto">
                <div className="flex flex-col items-center justify-center text-center bg-white p-6 sm:p-12">
                    <img src={RejectedImg} alt="Rejected" className="h-64" />
                    <h1 className="text-gray-900 font-semibold sm:text-4xl text-3xl mb-2">crédito rechazado</h1>
                    <p className="max-w-xl">Lo sentimos, tu solicitud de crédito fue rechazada, puedes volver a intentarlo en 60 días. Recibirás un email con los detalles.</p>
                </div>
            </div>
        </>
    )
}
