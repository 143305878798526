import React, { useContext, useState, useEffect, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import RequestLoanContext from 'context/RequestLoan'
import BankIcon from 'assets/icons/bank.svg'
import { ROUTES } from 'routes'
import Button from 'components/Button'
import getAccountTitle from 'utils/getAccountTitle'
import { currencyFormat } from 'utils/currencyFormat'

export default function Summary() {
    const navigate = useNavigate()

    const { state, saveState, updateLoan } = useContext(RequestLoanContext)
    const { loan } = state
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)

    const onBackward = () => {
        navigate(ROUTES.PAYMENT_PROFILE)
    }

    const onContinue = async () => {
        await updateLoan({
            id: state.loan.id,
            payload: {
                status: 'reviewing'
            }
        })
        navigate(ROUTES.LOAN_SUCCESS)
    }

    const monthlyPayment = () => {
        return (loan.amount + loan.amount * (loan.commission / 100)) / (loan.months * 2)
    }

    useEffect(() => {
        if (state?.loan?.payment_profile_id && state?.loan?.payment_profile_id === null) {
            navigate(ROUTES.PAYMENT_PROFILE)
        }
    }, [])

    return (
        <Fragment>
            <div className="flex flex-col grow py-16 max-w-4xl mx-auto" autoComplete="off">
                <div className="text-center mb-12">
                    <h1 className="text-gray-900 font-semibold sm:text-4xl text-3xl mb-2">Resumen del crédito</h1>
                </div>

                <div className="bg-primary-100 border border-dashed border-primary-500 rounded-lg text-center py-5 mx-4">
                    <h2 className="text-secondary-800 text-4xl font-semibold mb-1">{currencyFormat(loan.amount)}</h2>
                    <p className="text-sm font-light">Plazo de pago {loan.months} meses</p>
                </div>

                <div className="my-10 flex flex-col gap-y-8">
                    <div className="">
                        <div className="text-secondary-800 font-medium pl-4">Datos del crédito</div>
                        <div className="text-sm p-4">
                            <div className="flex justify-between py-3">
                                <div className="mr-2">Importe del crédito</div>
                                <p className="font-medium">{currencyFormat(loan.amount)}</p>
                            </div>
                            <div className="flex justify-between py-3">
                                <div className="mr-2">Comisión por crédito</div>
                                <p className="font-medium">{currencyFormat(loan.amount * (loan.commission / 100))}</p>
                            </div>
                            <div className="flex justify-between py-3 border-t border-b">
                                <div className="mr-2">Importe total adeudado</div>
                                <p className="font-medium">{currencyFormat(loan.amount + loan.amount * (loan.commission / 100))}</p>
                            </div>
                            <div className="flex justify-between text-primary-500 py-3">
                                <div className="mr-2">Pago bisemanal</div>
                                <p className="font-medium">{currencyFormat(monthlyPayment())}</p>
                            </div>
                        </div>
                    </div>
                    <div className="p-4">
                        <div className="text-secondary-800 font-medium mb-3">Perfil de pago</div>
                        <div className="text-sm bg-gray-50 p-4 flex rounded">
                            <div className="h-10 w-10 bg-white mr-4 grow-0 shrink-0 rounded-full flex">
                                <img className="m-auto" alt="icon_card" src={BankIcon} />
                            </div>
                            <div className="mb-2 text-gray-500-kiwi">
                                <p className="text-secondary-800 font-medium mb-1">{getAccountTitle(state.paymentProfile.accountNumber)}</p>
                                <p className="text-xs font-light">Cuenta principal</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-12 flex items-center justify-center gap-3">
                    <Button onClick={onBackward} className="bg-white border border-gray-500 text-gray-500 rounded-lg px-5" type="button">
                        Volver
                    </Button>
                    <Button className="bg-primary-500 text-white text-sm px-10 py-2 rounded-md w-auto" onClick={onContinue}>
                        Solicitar crédito
                    </Button>
                </div>
            </div>
        </Fragment>
    )
}
