import React, { Fragment, useContext, useEffect, useState } from 'react'
import { ExclamationCircleIcon, MailIcon } from '@heroicons/react/outline'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import 'dayjs/locale/es'

import Button from 'components/Button'
import RequestLoanContext from 'context/RequestLoan'
import { currencyFormat } from 'utils/currencyFormat'
import { ROUTES } from 'routes'
import CustomOfferModal from 'components/Modals/CustomOffer'
import { OfferServices } from 'services/offers/offers.service'
import { OFFER_OPTIONS } from 'constants'

export default function ApprovedLoanOffers() {
    dayjs.locale('es')

    const { state: requestState, saveState, createLoan, updateLoan } = useContext(RequestLoanContext)
    const [offerOptions, setOfferOptions] = useState(OFFER_OPTIONS)
    const navigate = useNavigate()

    const [isLoading, setLoading] = useState(true)

    const [offerAmount, setOfferAmount] = useState()

    const [offerSelected, setOfferSelected] = useState(null)
    const [offerSelectedValues, setOfferSelectedValues] = useState({})

    const [isCustomOfferVisible, setCustomOfferVisible] = useState(false)

    const OfferCard = ({ id, title, subtitle = '', description, amount, onClick = () => {} }) => (
        <button onClick={onClick} className={`border py-6 px-5 rounded-lg text-left text-xs ${offerSelected?.id === id ? 'border-primary-500 bg-primary-100' : 'hover:border-primary-500'}`}>
            <div className="flex justify-between">
                <h3 className="font-bold text-xl">{offerSelected?.id === id && id === 'personalized' ? currencyFormat(offerSelected.value) : title}</h3>
                {offerSelected?.id === id && id === 'personalized' ? <span className="bg-primary-500 text-white px-4 py-1 rounded-2xl items-center flex max-h-min h-full">Personalizar</span> : <></>}
            </div>
            <h4 className='font-bold text-sm mt-3'>{subtitle}</h4>
            {id !== 'personalized' && <p className={`${!offerSelected && 'bg-gray-200 animate-pulse px-10 rounded-sm h-3 max-w-[10rem] mt-1'}`}>{offerSelected && description}</p>}
            {id === 'personalized' && <p>{description}</p>}
        </button>
    )

    const onSubmit = async () => {
        setLoading(true)
        let loan = null

        if (requestState?.loan?.id) {
            loan = await updateLoan({
                id: requestState.loan.id,
                payload: {
                    amount: offerSelected.value,
                    commission: offerSelected.comission,
                    months: offerSelected.payments
                }
            })
        } else {
            loan = await createLoan(offerSelected.value, offerSelected.comission, offerSelected.payments)
        }
        setLoading(false)
        if (loan.success) {
            saveState({
                ...requestState,
                offers: {
                    ...requestState.offers,
                    selected: offerSelected
                },
                loan: loan.data
            })

            navigate(ROUTES.PAYMENT_PROFILE)
        }
    }

    const getCurrentOffers = async () => {
        try {
            const response = await OfferServices.getOffers(requestState.application.id)
            if(!response.success) throw new Error()
            for(const offer of response.data){
                let months = offer.months
                let currentPayments = offerOptions.find(item => item.payments === months);
                let index = offerOptions.findIndex(item => item.payments === months);

                if(index !== -1){
                    const newValueComission ={
                        id: currentPayments.id,
                        payments: currentPayments.payments,
                        comission: offer.percentage
                    }
                    offerOptions[index] = newValueComission;
                    setOfferOptions(prevData => {
                        return prevData.map(item => {
                            if (item.payments === months) {
                            return { ...item, value: offerOptions[index] = newValueComission };
                        }
                        return item;
                        });
                    });
                }
            }
            setOfferSelectedInitialValues()
            setLoading(false)
        } catch (error) {
            return
        }
    }

    const calculateOfferValues = () => {
        const amount = offerSelected.value
        const commission = (offerSelected.comission / 100) * amount
        const total = amount + commission
        const totalPayments = offerSelected.payments
        const paymentAmount = (amount + commission) / (totalPayments * 2)

        const currentDate = dayjs().add(14, 'days')
        const paymentDates = Array.from({ length: totalPayments * 2 }).map((_, index) => currentDate.add(2 * index, 'week'))

        setOfferSelectedValues({
            amount,
            commission,
            total,
            totalPayments,
            paymentDates,
            paymentAmount
        })
        setLoading(false)
    }

    const setOfferSelectedInitialValues = () => {
        if (requestState.offers.selected) {
            setOfferSelected(requestState.offers.selected)
        } else {
            setOfferSelected({
                ...offerOptions[0],
                value: requestState.offers.offer_amount
            })
        }
    }

    useEffect(() => {
        getCurrentOffers()
        setOfferAmount(requestState.offers.offer_amount)
    }, [])

    useEffect(() => {
        if (offerSelected) calculateOfferValues()
    }, [offerSelected])

    return (
        <>
            <CustomOfferModal offerOptions={offerOptions} payments={offerSelected?.payments} value={offerSelected?.value} limit={offerAmount} onSubmit={setOfferSelected} isVisible={isCustomOfferVisible} closeModal={setCustomOfferVisible} />
            <div className="py-16 px-6 m-auto">
                <div className="flex flex-col bg-white gap-12">
                    <div className="flex flex-col md:flex-row items-start md:justify-between">
                        <h1 className="text-gray-900 font-semibold sm:text-4xl text-3xl">Tienes una oferta de crédito</h1>
                        <div className="underline text-primary-500 font-semibold flex justify-center md:justify-start gap-10 mt-4">
                            <a href="mailto:support@kiwicapital.co" className="flex gap-1 cursor-pointer" target='_blank' rel='noreferrer'>
                                <MailIcon className="h-6 w-6" />
                                Contáctenos
                            </a>
                        </div>
                    </div>

                    <div>
                        <h6>Selecciona tu oferta</h6>
                        <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-4 mt-3">
                            {offerOptions.map(offer => (
                                <OfferCard
                                    onClick={() =>
                                        setOfferSelected({
                                            ...offer,
                                            value: offerAmount
                                        })
                                    }
                                    key={offer.id}
                                    id={offer.id}
                                    title={`${currencyFormat(offerAmount)}`}
                                    subtitle={`Plazo de pago ${offer.payments} meses`}
                                    description={`Comisión del crédito de ${currencyFormat((offer.comission / 100) * offerAmount)}`}
                                />
                            ))}
                            <OfferCard onClick={() => setCustomOfferVisible(true)} id="personalized" amount={`${currencyFormat(offerAmount)}`} title={'Personalizar Oferta'} description={`Elige un monto personalizado hasta ${currencyFormat(offerAmount)}`} />
                        </div>
                    </div>

                    <div className="grid md:grid-cols-2 gap-12 md:gap-4 xl:gap-20">
                        <div>
                            <h2 className="text-secondary-800 font-semibold text-lg mb-4">Datos del crédito</h2>

                            <ul className="mt-6 flex flex-col gap-4">
                                <li className="flex gap-2 justify-between text-gray-600">
                                    Importe del crédito <span className={`font-bold text-gray-900 ${!offerSelectedValues?.amount && 'bg-gray-200 animate-pulse px-10 rounded-md min-w-[10rem]'} `}>{offerSelectedValues?.amount && currencyFormat(offerSelectedValues?.amount)}</span>
                                </li>
                                <li className="flex gap-2 justify-between text-gray-600">
                                    Comisión por crédito <span className={`font-bold text-gray-900 ${!offerSelectedValues?.commission && 'bg-gray-200 animate-pulse px-10 rounded-md min-w-[10rem]'} `}>{offerSelectedValues?.commission && currencyFormat(offerSelectedValues?.commission)}</span>
                                </li>
                                <li className="flex gap-2 justify-between border-t pt-4 text-gray-600">
                                    Importe total adeudado <span className={`font-bold text-gray-900 ${!offerSelectedValues?.total && 'bg-gray-200 animate-pulse px-10 rounded-md min-w-[10rem]'} `}>{offerSelectedValues?.total && currencyFormat(offerSelectedValues?.total)}</span>
                                </li>
                                <li className={`flex gap-2 justify-between border-t pt-4 text-primary-500`} >
                                    Pago bisemanal <span className={`${!offerSelectedValues?.paymentAmount && 'bg-primary-500 bg-opacity-20 animate-pulse px-10 rounded-md min-w-[10rem]'} font-semibold`}>{offerSelectedValues?.paymentAmount && `$ ${offerSelectedValues?.paymentAmount?.toFixed(2)}`}</span>
                                </li>
                            </ul>
                        </div>
                        <div className='flex flex-col gap-4'>
                            <h2 className="text-secondary-800 font-semibold text-lg">Listado de pagos</h2>
                            <div className="flex gap-2 py-2 px-4 bg-[#FFF7EB] rounded-lg items-center text-xs">
                                <div className="bg-[#FFE9C7] text-[#FFB84D] p-2 rounded-full">
                                    <ExclamationCircleIcon className="h-5 w-5" />
                                </div>
                                <p>Las fechas pueden oscilar entre <b>1 a 3 días,</b> pero la fecha precisa del <b>primer pago se establece en 14 días a partir de la firma del contrato.</b></p>
                            </div>
                            {!offerSelectedValues?.paymentDates ?
                                <div className='w-full rounded-md bg-gray-200 animate-pulse min-h-[12rem]'></div>
                                :
                                <ul className="space-y-5">
                                    {offerSelectedValues?.paymentDates?.map((value, i) => (
                                    <li key={i} className="relative flex gap-x-4">
                                        {i < offerSelectedValues.totalPayments * 2 - 1 && (
                                            <div className="absolute left-0 top-0 flex w-6 justify-center -bottom-6">
                                                <div className="w-px bg-gray-200"></div>
                                            </div>
                                        )}
                                        <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                                            <div className="h-1.5 w-1.5 rounded-full bg-gray-200 ring-1 ring-gray-300"></div>
                                        </div>
                                        <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                            <span className="font-medium text-gray-900">{i + 1}° pago</span>
                                        </p>
                                        <time dateTime="2023-01-23T10:32" className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                                            {value.format('D [de] MMMM [de] YYYY')}
                                        </time>
                                    </li>
                                    ))}
                                </ul>
                            }
                        </div>
                    </div>

                    <div className="flex items-center justify-center gap-3">
                        <Button onClick={()=>{
                            if(offerSelected) onSubmit()
                        }} className={`${offerSelected ? 'bg-primary-500' : 'bg-gray-500'}  text-white text-sm px-10 py-2 rounded-md w-auto`}type="submit" loading={isLoading}>
                            Continuar
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}
