import { ArrowRightIcon } from '@heroicons/react/outline'
import { useEffect, useState } from 'react'
import { createLinkToken, exchangePlaidToken } from 'utils/plaid'
import { usePlaidLink } from 'react-plaid-link'
import LoadingModal from 'components/Modals/Loading'

export default function BankAccountBanner({hasCreated}) {
    const [linkTokenPlaid, setLinkTokenPlaid] = useState('')
    const [isOpenPlaid, setIsOpenPlaid] = useState(false)
    const [loading, setLoading] = useState(false)

    const createTokenPlaid = async () => {
        try {
            const { data } = await createLinkToken()
            setLinkTokenPlaid(data.data.link_token)
        } catch (error) {
            console.error(error)
        }
    }

    const { open: openPlaid } = usePlaidLink({
        token: linkTokenPlaid,
        onEvent: (event)=>{
            if(event === 'OPEN') {
                return setIsOpenPlaid(true)
            }

            if(event === 'EXIT') {
                return setIsOpenPlaid(false)
            }
        },
        onSuccess: async (public_token) => {
            try {
                setLoading(true)
                const data = await exchangePlaidToken(public_token)
                if (data.data.success) {
                    setIsOpenPlaid(false)
                    hasCreated(true)
                }
            } catch (error) {
                console.error(error)
            } finally{
                setLoading(false)
            }
        }
    })

    useEffect(() => {
        createTokenPlaid()
    }, [])
    

    return (
        <>
            {isOpenPlaid && <LoadingModal isVisible={isOpenPlaid} isLoading={loading} />}
            <div className="flex flex-col text-center sm:flex-row items-center justify-center gap-x-4 text-sm leading-6 bg-secondary-700 px-6 py-2.5 sm:px-3.5">
                <p className="text-white">Conecta tu cuenta de banco para mejorar las posibilidades de oferta.</p>
                <button onClick={openPlaid} className="text-primary-500 flex items-center gap-1">
                    Conectar ahora
                    <ArrowRightIcon className="h-4 w-4" />
                </button>
            </div> 
        </>  
    )
}
