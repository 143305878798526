import React, { useRef, useState, useContext, useEffect } from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { NumericFormat, PatternFormat } from 'react-number-format'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import AuthContext from 'context/Auth'
import Button from 'components/Button'
import { moneyStringToNumeric } from 'utils/moneyStringToNumeric'
import { ROUTES } from 'routes'
import { CREDIT_USE_OPTIONS } from 'constants'
import { CODE_SENT } from 'constants'
import HeaderApp from 'components/Header'

export default function RequestLoanAmount() {
    const navigate = useNavigate()
    const { state, saveState, sendPhone } = useContext(AuthContext)

    const form = useRef()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()

    const [initialValues, setInitialValues] = useState({
        surveyCreditLimit: '',
        surveyReason: '',
        phone: ''
    })

    const validationSchema = Yup.object().shape({
        surveyCreditLimit: Yup.string()
            .trim()
            .required('Este campo es requerido')
            .test('min-amount', 'Escribe un monto mayor o igual a $500', money => {
                if (money) {
                    let amount = Number(money.replace(',', '').replace('$ ', ''))
                    if (amount >= 500) {
                        return true
                    }
                }
            }),
        surveyReason: Yup.string().trim().required('Este campo es requerido'),
        phone: Yup.string()
            .required('Este campo es requerido')
            .test('minPhone', 'El teléfono debe ser de 10 dígitos', val => val && val.length === 14)
    })

    useEffect(() => {
        if (state.phone) {
            setInitialValues({
                ...initialValues,
                phone: state.phone
            })
        }
    }, [state.phone])

    const onSubmit = async values => {
        setLoading(true)
        setError()
        const { success, data } = await sendPhone(values.phone)
        if (!success || data?.status !== CODE_SENT) {
            setError('Parece que hubo un problema. Inténtalo de nuevo por favor')
            return setLoading(false)
        }

        saveState({
            ...state,
            phone: values.phone,
            application: {
                surveyCreditLimit: moneyStringToNumeric(values.surveyCreditLimit),
                surveyReason: values.surveyReason,
                partner: process.env.REACT_APP_PARTNER
            }
        })

        navigate(ROUTES.CODE_VERIFICATION)
    }

    return (
        <>
            <HeaderApp />
            <Formik initialValues={initialValues} enableReinitialize={true} validationSchema={validationSchema} onSubmit={onSubmit} innerRef={form}>
                {({ errors, touched }) => (
                    <div className="max-w-4xl py-10 lg:pt-20 m-auto">
                        <Form className="flex flex-col bg-white p-6 sm:p-12" autoComplete="off">
                            <div className="text-center mb-12">
                                <h1 className="text-gray-900 font-semibold sm:text-4xl text-3xl mb-2">Cuéntanos sobre tus necesidades</h1>
                            </div>

                            <div className="flex flex-col gap-6">
                                <div>
                                    <label htmlFor="surveyCreditLimit" className="inline-block text-sm mb-2">
                                        ¿Qué límite de crédito necesitas?
                                    </label>

                                    <Field name="surveyCreditLimit" id="surveyCreditLimit">
                                        {({ field, ...props }) => (
                                            <NumericFormat
                                                isAllowed={values => {
                                                    const { formattedValue, floatValue } = values
                                                    return formattedValue === '' || floatValue <= 999999
                                                }}
                                                {...field}
                                                {...props}
                                                id="surveyCreditLimit"
                                                thousandSeparator={true}
                                                prefix={'$ '}
                                                allowNegative={false}
                                                decimalScale={0}
                                                fixedDecimalScale={true}
                                                max={999999}
                                                min="3"
                                                placeholder="Ingresa una cantidad (ej. $5,000)"
                                                autoComplete="off"
                                                className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.surveyCreditLimit && touched.surveyCreditLimit ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="surveyCreditLimit" component="small" className="text-red-500 inline-block text-s w-full" />
                                </div>
                                <div>
                                    <label htmlFor="surveyReason" className="inline-block text-sm mb-2">
                                        ¿Para que utilizarás la línea de crédito?
                                    </label>

                                    <div className="relative flex items-center cursor-pointer after:w-[8px] after:h-[8px] after:border-black/70 after:border-b after:border-r after:transform after:rotate-45 after:absolute after:right-4 after:bottom-5">
                                        <Field
                                            as="select"
                                            id="surveyReason"
                                            name="surveyReason"
                                            placeholder="Seleccionar"
                                            className={`focus:ring-0 w-full cursor-pointer appearance-none rounded-lg outline-none py-2 px-4 border border-solid ${
                                                errors.surveyReason && touched.surveyReason ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'
                                            }`}
                                            disabled={loading}>
                                            <option disabled value="">
                                                Seleccionar
                                            </option>
                                            {CREDIT_USE_OPTIONS.map(credit => (
                                                <option key={credit} value={credit}>
                                                    {credit}
                                                </option>
                                            ))}
                                        </Field>
                                    </div>
                                    <ErrorMessage name="surveyReason" component="small" className="text-red-500 inline-block text-s w-full" />
                                </div>
                                <div>
                                    <label htmlFor="phone" className="inline-block text-sm mb-2">
                                        Ingresa tu número de celular
                                    </label>
                                    <Field id="phone" name="phone">
                                        {({ field, form: { touched, errors } }) => (
                                            <div className="relative">
                                                <div className={`absolute top-1/2 left-4 -translate-y-1/2 ${errors.phone && touched.phone ? 'text-red-kiwi' : ''}`}>+1</div>
                                                <PatternFormat
                                                    className={`w-full rounded-lg py-2 border px-4 pl-10 ${errors.phone && touched.phone ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-red-500/50' : 'border-gray-200 bg-white focus:border-primary-500 placeholder:text-gray-400'}`}
                                                    format="(###) ### ####"
                                                    placeholder="(000) 000 0000"
                                                    mask=""
                                                    {...field}
                                                />
                                            </div>
                                        )}
                                    </Field>
                                    <ErrorMessage name="phone" component="small" className="text-red-500 inline-block text-s w-full" />
                                </div>
                            </div>

                            <div className="flex flex-col items-center justify-center mt-12 gap-6">
                                {error && <p className="text-red-500 inline-block text-sm text-center w-full">{error}</p>}
                                <Button className="bg-primary-500 text-white text-sm px-10 py-2 rounded-md w-auto" type="submit" loading={loading}>
                                    Continuar
                                </Button>
                                <a className="text-sm text-gray-600 flex flex-col items-center justify-center" href={ROUTES.LOGIN}>
                                    Ya tengo una cuenta <br /> <span className="text-primary-500 underline">Iniciar sesión</span>
                                </a>
                            </div>
                        </Form>
                    </div>
                )}
            </Formik>
        </>
    )
}
