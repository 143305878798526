import { useContext, useEffect, useState, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import phoneFormatter from 'phone-formatter'
import { ROUTES } from 'routes'

import AuthContext from 'context/Auth'
import HeaderApp from 'components/Header'
import Button from 'components/Button'
import ResendCodeModal from 'components/Modals/ResendCode'
import { CODE_VERIFIED } from 'constants'
import { TwilioService } from 'services/twilio/twilio.service'
/* import { JWT_KEY } from 'utils/http' */

export default function RequestLoanCodeVerification() {
    const navigate = useNavigate()
    const { state: authState, saveState, getUserStatus, createAuthToken } = useContext(AuthContext)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [codeValues, setCodeValues] = useState(['', '', '', '', '', ''])

    /**
     * Manejar el ingreso del código...
     */
    const onKeyDownCode = (event, codeIndex) => {
        let isDeleteKey = event.code === 'Backspace' || event.key === 'Backspace' || event.keyCode === 8
        let currentInput = event.target
        let code = codeValues[codeIndex]

        if (isDeleteKey) {
            /**  Al borrar número, recorrer el focus del input... */
            let previousInput = currentInput.previousSibling
            if (previousInput !== null && code === '') {
                previousInput.focus()
            }
        } else {
            /** Validación permitir solo un dígito... */
            if (currentInput.value.length > 0) {
                event.preventDefault()
            }

            /** Validar que el input sea numérico... */
            if (!/[0-9]/.test(event.key)) {
                event.preventDefault()
            }
        }
    }

    const onChangeCode = (event, codeIndex) => {
        let newCodeValues = [...codeValues]
        let currentInput = event.target

        newCodeValues[codeIndex] = currentInput.value

        setCodeValues(newCodeValues)
        setError('')

        if (currentInput.value === '') {
            return
        }

        /** Al escribir un dígito brincar al siguiente input... */
        let nextInput = currentInput.nextSibling
        if (nextInput !== null) {
            nextInput.focus()
        } else {
            currentInput.blur()
        }
    }
    /**
     * Manejar el reenvio del código...
     */
    const [resendCount, setResendCount] = useState(30)
    const [resendCountStart, setResendCountStart] = useState(false)

    const onResendCode = async _type => {
        setResendVisible(false)
        setResendCountStart(true)

        let timeLeft = resendCount
        let resendInterval = setInterval(() => {
            timeLeft = timeLeft - 1

            if (timeLeft <= 0) {
                clearInterval(resendInterval)
                setResendCount(30)
                setResendCountStart(false)
            } else {
                setResendCount(timeLeft)
            }
        }, 1000)

        try {
            // if (type === 'phone') {
            //     navigate('/login')
            // } else if (type === 'sms') {
            //     login({ cellphone })
            // } else if (type === 'whatsapp') {
            //     login({ cellphone, service: 'whatsapp' })
            // }
        } catch (error) {
            setError('Parece que hubo un problema al reenviar tu código. Inténtalo de nuevo por favor.')
        }
    }

    /**
     * Enviar el código de verificación...
     */
    const onVerifyCode = async () => {
        setLoading(true)
        setError()
        let code = codeValues.join('')

        if (code === '') {
            return setError('Ingresa tu código de verificación')
        }

        if (code.length < 6) {
            return setError('Ingresa todos los dígitos de verificación')
        }

        const { success, data } = await TwilioService.validateCode(authState.phone, code)

        if (!success || data?.status !== CODE_VERIFIED) {
            if (data.validation === 'pending') {
                setError(`El código de validación es incorrecto. Inténtalo de nuevo por favor`)
            } else {
                setError('Parece que hubo un problema. Inténtalo de nuevo por favor')
            }
            return setLoading(false)
        }

        if (data.new_user) {
            const user = await getUserStatus()
            saveState({
                ...authState,
                ...user.data,
                token: data.token
            })
            return navigate(ROUTES.BUSINESS)
        }

        saveState({
            ...authState,
            phoneVerification: {
                completed: true
            }
        })
        await createAuthToken({ token: data.token })
        navigate(ROUTES.OFFERS)
    }

    /**
     * Funcionalidad para abrir y cerrar el modal de reenvio del código...
     */
    const [resendVisible, setResendVisible] = useState(false)

    const onOpenResendModal = () => {
        if (resendCountStart === false) {
            setResendVisible(true)
        }
    }

    const onCloseResendModal = () => {
        setResendVisible(false)
    }

    useEffect(() => {
        if (!authState.phone) navigate(ROUTES.LOGIN)
    }, [])

    return (
        <>
            {authState.phone && (
                <>
                    <ResendCodeModal cellphone={phoneFormatter.format(authState.phone, '+1 (NNN) NNN-NNNN')} isVisible={resendVisible} closeModal={onCloseResendModal} resendCode={onResendCode} />
                    <HeaderApp />
                    <div className="max-w-4xl py-10 lg:pt-20 m-auto">
                        <div className="flex flex-col bg-white p-6 sm:p-12" autoComplete="off">
                            <div className="text-center">
                                <h1 className="text-gray-900 font-semibold sm:text-4xl text-3xl mb-2">Ingresa el código de verificación</h1>
                                <p>Código de 6 dígitos que te enviamos a tu número de celular {phoneFormatter.format(authState.phone, '+1 (NNN) NNN-NNNN')}</p>
                            </div>
                            <div className="flex flex-col items-center justify-center mt-6 sm:mt-auto">
                                <div className="flex gap-4 sm:mt-10">
                                    {[...Array(6)].map((_value, i) => (
                                        <input
                                            className={`border py-0 px-0 text-secondary-800 font-semibold text-2xl text-center w-1/6 max-w-[56px] grow-0 h-16 rounded-lg ${
                                                error ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-red-500/50' : 'border-gray-200 focus:border-primary-500 bg-gray-100 placeholder:text-gray-400'
                                            }`}
                                            key={i}
                                            type="number"
                                            inputMode="numeric"
                                            onKeyDown={e => onKeyDownCode(e, i)}
                                            onChange={e => onChangeCode(e, i)}
                                        />
                                    ))}
                                </div>
                                {error !== '' && <small className="text-red-500 inline-block text-sm text-center w-full mt-2">{error}</small>}
                                {/*                                 <p className="mt-10">
                                    ¿No recibiste el código?{' '}
                                    <span className="cursor-pointer text-primary-500 underline" onClick={onOpenResendModal}>
                                        {resendCountStart === false ? 'Reenviar' : `Espera ${resendCount}s para volver a reenviar`}
                                    </span>
                                </p> */}
                            </div>

                            <div className="flex flex-col items-center justify-center mt-12">
                                <Button onClick={onVerifyCode} className="bg-primary-500 text-white text-sm px-10 py-2 rounded-md w-auto" type="submit" loading={loading}>
                                    Continuar
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
