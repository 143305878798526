import React, { useContext, useEffect, useRef, useState } from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import Button from 'components/Button'
import RequestLoanContext from 'context/RequestLoan'

import { ROUTES } from 'routes'
import { BANKS_PR } from 'constants'
import PaymentProfilesList from 'components/PaymentProfiles/List'

export default function ApprovedLoanPaymentProfile() {
    const { state: requestState, saveState, getPaymentProfiles, createPaymentProfile, updateLoan } = useContext(RequestLoanContext)
    const navigate = useNavigate()

    const form = useRef()
    const [paymentProfiles, setPaymentProfiles] = useState([])
    const [selectedPaymentProfile, setSelectedPaymentProfile] = useState(null)
    const [loading, setLoading] = useState(false)
    const [bank, setBank] = useState({
        name: '',
        routingNumber: '',
        maxDigitsAccount: ''
    })

    const validationSchema = Yup.object().shape({
        accountType: Yup.string().trim().required('Este campo es requerido'),
        bank: Yup.string().trim().required('Este campo es requerido'),
        accountNumber: Yup.string()
            .required('Este campo es requerido')
            .test('equal', `Escribe los ${bank.maxDigitsAccount ?? ''} dígitos de tu número de cuenta`, val => val?.length === bank.maxDigitsAccount || bank.maxDigitsAccount === '')
            .test('onlyNumbers', `El número de cuenta debe ser numérico`, val => {
                if (val === undefined) {
                    return false
                }

                if (/^[0-9]+$/.test(val)) {
                    return true
                }
            }),
        confirmAccountNumber: Yup.string()
            .required('Este campo es requerido')
            .oneOf([Yup.ref('accountNumber')], 'Los números de cuenta no coinciden'),
        routingNumber: Yup.string().required('Este campo es requerido')
    })

    const init = async () => {
        const paymentProfiles = await getPaymentProfiles()
        saveState({
            ...requestState,
            paymentProfiles: paymentProfiles.data
        })
        setPaymentProfiles(paymentProfiles.data)
    }

    const onCreate = async ({ accountType, accountNumber, routingNumber }) => {
        setLoading(true)
        const { data } = await createPaymentProfile({
            accountType,
            accountNumber,
            routingNumber
        })

        await updateLoan({
            id: requestState.loan.id,
            payload: {
                payment_profile_id: data.id
            }
        })

        saveState({
            ...requestState,
            paymentProfile: {
                accountType,
                accountNumber,
                routingNumber,
                id: data.id,
                completed: true
            }
        })

        navigate(ROUTES.SUMMARY)
        setLoading(false)
    }

    const onSubmit = async ({ id, account_type, account_number, routing_number }) => {
        setLoading(true)

        await updateLoan({
            id: requestState.loan.id,
            payload: {
                payment_profile_id: id
            }
        })

        saveState({
            ...requestState,
            paymentProfile: {
                accountType: account_type,
                accountNumber: account_number,
                routingNumber: routing_number,
                id: id,
                completed: true
            }
        })

        navigate(ROUTES.SUMMARY)
        setLoading(false)
    }

    const onBackward = () => {
        saveState({
            ...requestState,
            paymentProfile: {
                ...requestState.paymentProfile,
                completed: false
            },
            offers: {
                ...requestState.offers,
                completed: false
            }
        })

        navigate(ROUTES.OFFERS)
    }

    const onSelectBank = event => {
        let bank = BANKS_PR[event.target.value]
        setBank(bank)

        form.current.setFieldValue('bank', bank.index)
        form.current.setFieldValue('routingNumber', bank.routingNumber)
    }

    useEffect(() => {
        init()
    }, [])

    return (
        <div className="py-16">
            <div className="text-center mb-2">
                <h1 className="text-gray-900 font-semibold sm:text-4xl text-3xl">Agrega tu perfil de pago</h1>
            </div>
            <div className="max-w-4xl py-16 m-auto">
                {paymentProfiles.length ? (
                    <div>
                        <PaymentProfilesList value={selectedPaymentProfile} accounts={paymentProfiles} onSelect={setSelectedPaymentProfile} />
                        <p className="mt-6 text-gray-500 text-sm text-center">
                            Si quieres cambiar tu cuenta de banco puedes comunicarte con nosotros al correo <b>support@kiwicredito.com</b>
                        </p>
                        <div className="mt-12 flex items-center justify-center gap-3">
                            <Button onClick={onBackward} className="bg-white border border-gray-500 text-gray-500 rounded-lg px-5" type="button">
                                Volver
                            </Button>
                            <Button
                                className={`text-sm px-10 py-2 rounded-md w-auto ${!selectedPaymentProfile ? 'bg-gray-200 text-gray-500' : 'bg-primary-500 text-white '}`}
                                onClick={() => {
                                    onSubmit(selectedPaymentProfile)
                                }}
                                disabled={!selectedPaymentProfile}
                                loading={loading}>
                                Continuar
                            </Button>
                        </div>
                    </div>
                ) : (
                    <Formik initialValues={requestState.paymentProfile} validationSchema={validationSchema} onSubmit={onCreate} innerRef={form}>
                        {({ errors, touched }) => (
                            <Form className="flex flex-col bg-white px-6 sm:px-12" autoComplete="off">
                                <div className="flex flex-col gap-6">
                                    <div>
                                        <label htmlFor="accountType" className="inline-block text-sm mb-2">
                                            Tipo de cuenta
                                        </label>
                                        <div className="flex gap-4">
                                            <div className="relative flex w-2/4">
                                                <Field className="peer hidden" type="radio" id="accountTypeOpt1" name="accountType" value="checking" />
                                                <div
                                                    className={`pointer-events-none peer-checked:after:block after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:block after:w-3 after:h-3 after:peer-checked:bg-primary-500  after:rounded-full absolute left-4 top-1/2 rounded-full w-5 h-5 peer-checked:border-primary-500 border border-solid -translate-y-1/2 ${
                                                        errors.accountType && touched.accountType ? 'border-red-500/50' : 'border-gray-400 '
                                                    }`}></div>
                                                <label
                                                    className={`pl-12 peer-checked:border-primary-500 peer-checked:bg-primary-500/10 w-full rounded-lg outline-none py-2 px-4 border border-solid border-gray-200 ${
                                                        errors.accountType && touched.accountType ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-red-500/50' : ''
                                                    }`}
                                                    htmlFor="accountTypeOpt1">
                                                    Checking
                                                </label>
                                            </div>

                                            <div className="relative flex w-2/4">
                                                <Field className="peer hidden" type="radio" id="accountTypeOpt2" name="accountType" value="savings" />
                                                <div
                                                    className={`pointer-events-none peer-checked:after:block after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:block after:w-3 after:h-3 after:peer-checked:bg-primary-500 after:rounded-full absolute left-4 top-1/2 rounded-full w-5 h-5 peer-checked:border-primary-500 border border-solid -translate-y-1/2 ${
                                                        errors.accountType && touched.accountType ? 'border-red-500/50' : 'border-gray-400 '
                                                    }`}></div>
                                                <label
                                                    className={`pl-12 peer-checked:border-primary-500 peer-checked:bg-primary-500/10 w-full rounded-lg outline-none py-2 px-4 border border-solid border-gray-200 ${
                                                        errors.accountType && touched.accountType ? 'border-red-500 focus:border-red-500 bg-red-500/5 placeholder:text-red-500/50' : ''
                                                    }`}
                                                    htmlFor="accountTypeOpt2">
                                                    Ahorros
                                                </label>
                                            </div>
                                        </div>
                                        <ErrorMessage name="accountType" component="small" className="text-red-500 inline-block text-s w-full" />
                                    </div>
                                    <div>
                                        <label htmlFor="bank" className="inline-block text-sm mb-2">
                                            Selecciona tu banco
                                        </label>
                                        <div className="relative flex items-center cursor-pointer after:w-[8px] after:h-[8px] after:border-black/70 after:border-b after:border-r after:transform after:rotate-45 after:absolute after:right-4 after:bottom-5">
                                            <Field
                                                as="select"
                                                id="bank"
                                                name="bank"
                                                placeholder="Seleccionar"
                                                onInput={onSelectBank}
                                                className={`focus:ring-0 w-full appearance-none cursor-pointer rounded-lg outline-none py-2 px-4 border border-solid ${errors.bank && touched.bank ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                                disabled={loading}>
                                                <option disabled value="">
                                                    Seleccionar
                                                </option>
                                                {BANKS_PR.map((value, i) => (
                                                    <option key={i} value={i}>
                                                        {value.name}
                                                    </option>
                                                ))}
                                            </Field>
                                        </div>
                                        <ErrorMessage name="bank" component="small" className="text-red-500 inline-block text-s w-full" />
                                    </div>
                                    <div>
                                        <label htmlFor="accountNumber" className="inline-block text-sm mb-2">
                                            Número de cuenta
                                        </label>
                                        <Field
                                            id="accountNumber"
                                            name="accountNumber"
                                            className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.accountNumber && touched.accountNumber ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                            disabled={loading}
                                        />
                                        <ErrorMessage name="accountNumber" component="small" className="text-red-500 inline-block text-s w-full" />
                                    </div>
                                    <div>
                                        <label htmlFor="confirmAccountNumber" className="inline-block text-sm mb-2">
                                            Confirmar número de cuenta
                                        </label>
                                        <Field
                                            id="confirmAccountNumber"
                                            name="confirmAccountNumber"
                                            className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.confirmAccountNumber && touched.confirmAccountNumber ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                            disabled={loading}
                                        />
                                        <ErrorMessage name="confirmAccountNumber" component="small" className="text-red-500 inline-block text-s w-full" />
                                    </div>
                                    <div>
                                        <label htmlFor="routingNumber" className="inline-block text-sm mb-2">
                                            Número de ruta
                                        </label>
                                        <Field
                                            id="routingNumber"
                                            name="routingNumber"
                                            className={`focus:ring-0 w-full rounded-lg outline-none py-2 px-4 border border-solid ${errors.routingNumber && touched.routingNumber ? 'border-red-500 focus:border-red-500 bg-red-500/5' : 'border-gray-200 focus:border-primary-500'}`}
                                            disabled={bank.routingNumber}
                                        />
                                        <ErrorMessage name="routingNumber" component="small" className="text-red-500 inline-block text-s w-full" />
                                    </div>
                                </div>

                                <div className="mt-12 flex items-center justify-center gap-3">
                                    <Button onClick={onBackward} className="bg-white border border-gray-500 text-gray-500 rounded-lg px-5" type="button">
                                        Volver
                                    </Button>
                                    <Button className="bg-primary-500 text-white text-sm px-10 py-2 rounded-md w-auto" type="submit" loading={loading}>
                                        Continuar
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </div>
    )
}
